import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BankInfo = () => {
  return (
    <div className="text-start">
      <h3>
        <FontAwesomeIcon icon={faPiggyBank} />
        &nbsp; 입금 계좌
      </h3>
      <p>(* 편한 곳 이용하세요.)</p>
      <p>토스뱅크 / 김종훈 / 1000-4389-2034</p>
      <p>KEB하나은행 / 김종훈 / 599-910296-50107</p>
      <p>국민은행 / 김종훈 / 812302-01-081836</p>
    </div>
  );
};

export default BankInfo;
