import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner.js";
import MsgBox from "../../../components/MsgBox.js";
import OriginalBoards from "../../../components/story/OriginalBoards";
import StoryItemsManage from "../../../components/story/StoryItemsManage";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useToast from "../../../hooks/useToast";

const StoryFormPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const { groupIdParam, _id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [openId, setOpenId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupIdErrMsg, setGroupIdErrMsg] = useState("");
  const [storyName, setStoryName] = useState("");
  const [storyNameErrMsg, setStoryNameErrMsg] = useState("");
  const [pass, setPass] = useState("");
  const [memo, setMemo] = useState("");
  const [publicStatus, setPublicStatus] = useState(null);
  const [publicStatusErrMsg, setPublicStatusErrMsg] = useState(null);
  const [groups, setGroups] = useState([]);
  const [storyBoards, setStoryBoards] = useState([]);
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const [publicStatuses, setPublicStatuses] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    axiosHook
      .get(`${apiServer}/publicStatus/childCodes`)
      .then((res) => {
        setPublicStatuses(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, apiServer]);

  useEffect(() => {
    setOpenId("");
    setGroupId(groupIdParam);
    setPass("");
    setStoryName("");
    setMemo("");
    setPublicStatus(null);
  }, [groupIdParam]);

  useEffect(() => {
    if (!_id) return;
    const loadingStory = async () => {
      await setIsLoading(true);
      await axiosHook
        .get(`${apiServer}/storyDetail/${_id}`)
        .then((res) => {
          const story = res.data.story;
          setGroupId(story?.groupId);
          setOpenId(story.openId);
          setStoryName(story.name);
          setPass(story.pass);
          setMemo(story.memo);
          setPublicStatus(story.publicStatus);
          // setStoryBoards(res.data.boards);
          /** res.data.boards 에서 빈 객체 {} 는 제외 */
          setStoryBoards(
            res.data.boards.filter((board) => Object.keys(board).length)
          );
        })
        .catch((err) => {
          console.error(err);
          addToast({
            type: "danger",
            text: err.message || "스토리 정보를 가져오는데 실패했습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    loadingStory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, axiosHook, apiServer]);

  useEffect(() => {
    setIsLoading(true);
    axiosHook
      .get(`${apiServer}/storyGroups`)
      .then((res) => {
        setGroups(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, apiServer]);

  const validate = () => {
    let isValid = true;
    setGroupIdErrMsg("");
    setStoryNameErrMsg("");
    setPublicStatusErrMsg("");
    if (!groupId) {
      setGroupIdErrMsg("스토리 그룹을 선택하세요.");
      isValid = false;
    }
    if (!storyName) {
      setStoryNameErrMsg("스토리 명을 입력하세요.");
      isValid = false;
    }
    if (!publicStatus) {
      setPublicStatusErrMsg("공개여부를 선택하세요.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    const data = {
      _id,
      name: storyName,
      pass,
      memo,
      publicStatus,
      groupId,
    };
    await setIsLoading(true);
    if (_id) {
      // 수정
      await axiosHook
        .put(`${apiServer}/storyEdit`, data)
        .then((res) => {
          if (res.data.ok) {
            addToast({
              type: "success",
              text: res.data.message || "스토리가 수정되었습니다.",
            });
          } else {
            addToast({
              type: "danger",
              text: res.data.message || "스토리 수정에 실패했습니다.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          addToast({
            type: "danger",
            text: err.message || "스토리 수정에 실패했습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // 생성
      await axiosHook
        .post(`${apiServer}/storyAdd`, data)
        .then((res) => {
          if (res.data.ok) {
            addToast({
              type: "success",
              text: res.data.message || "스토리가 생성되었습니다.",
            });
            navigate(`/manage/storyEdit/${res.data.result._id}`);
          } else {
            addToast({
              type: "danger",
              text: res.data.message || "스토리 생성에 실패했습니다.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          addToast({
            type: "danger",
            text: err.message || "스토리 생성에 실패했습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDeleteStory = async (e) => {
    e.preventDefault();
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    await setIsLoading(true);
    await axiosHook
      .delete(`${apiServer}/storyDelete`, { data: { _id } })
      .then((res) => {
        if (res.data.ok) {
          addToast({
            type: "success",
            text: res.data.message || "스토리가 삭제되었습니다.",
          });
          navigate("/manage/story");
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "스토리 삭제에 실패했습니다.",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        addToast({
          type: "danger",
          text: err.message || "스토리 삭제에 실패했습니다.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <h2
          className="mb-0"
          // dangerouslySetInnerHTML={{
          //   __html: _id
          //     ? `<a rel="noopener noreferrer" target="_self" href="/story/${openId}">${storyName}</a>`
          //     : "스토리 생성",
          // }}
        >
          {/** Link 방식으로 변경
           * dangerouslySetInnerHTML 로 처리하면 XSS 공격에 취약
           * Link 로 처리하면 XSS 공격에 안전
           */}
          <Link to={`/story/${openId}`}>{storyName}</Link>
        </h2>
        <div>
          <button
            className={`btn btn-sm btn-outline-danger ${_id ? "" : "d-none"}`}
            onClick={handleDeleteStory}
          >
            스토리 삭제
          </button>
        </div>
      </div>
      <ul>
        <li className={groupIdErrMsg ? "text-danger" : "d-none"}>
          스토리 그룹을 선택하세요.
        </li>
        <li className={storyNameErrMsg ? "text-danger" : "d-none"}>
          스토리 명을 입력하세요.
        </li>
        <li className={publicStatusErrMsg ? "text-danger" : "d-none"}>
          공개여부를 선택하세요.
        </li>
      </ul>
      <form>
        <div className="row align-items-start border border-secondary rounded-3 pt-3 mb-3">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <select
              className={`form-select form-select ${
                groupIdErrMsg ? "border-danger" : ""
              }`}
              value={groupId || ""}
              onChange={(e) => setGroupId(e.target.value)}
            >
              <option value="">스토리 그룹을 선택하세요</option>
              {groups?.map((group) => (
                <option key={group._id} value={group._id}>
                  {group.groupName}
                </option>
              ))}
            </select>
          </div>
          <div className={`mb-3 col-12 col-md-6 col-lg-4`}>
            <input
              type="text"
              className={`form-control ${
                storyNameErrMsg ? "border-danger" : ""
              }`}
              placeholder="스토리명을 입력하세요."
              value={storyName || ""}
              onChange={(e) => setStoryName(e.target.value)}
            />
          </div>
          <div className="mb-3 col-12 col-md-6 col-lg-2">
            <select
              className={`form-select form-select ${
                publicStatusErrMsg ? "border-danger" : ""
              }`}
              value={publicStatus || ""}
              onChange={(e) => setPublicStatus(e.target.value)}
            >
              <option value="">공개여부</option>
              {publicStatuses?.map((item, index) => {
                return (
                  <option key={index} value={item.code}>
                    {item.codeName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 col-12 col-md-6 col-lg-2">
            <input
              type="text"
              className="form-control"
              placeholder="패스워드"
              value={pass || ""}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div className="mb-3 col-12 col-lg-11">
            <input
              type="text"
              className="form-control"
              placeholder="메모(선택)"
              value={memo || ""}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
          <div className="mb-3 col-12 col-lg-1">
            <button
              className="btn btn-outline-primary w-100"
              onClick={handleSubmit}
            >
              저장
            </button>
          </div>
        </div>
      </form>
      <div className={`row align-items-start ${_id ? "" : "d-none"}`}>
        <div className="text-center text-danger small mb-2">
          * 스토리 아이템이 저장될 때 '스토리 공개' 옵션이 자동으로 활성화
          됩니다.
        </div>
        <div className="col-12 col-sm-6 border p-3 overflow-auto">
          <OriginalBoards
            storyBoards={storyBoards}
            setStoryBoards={setStoryBoards}
          />
        </div>
        <div className="col-12 col-sm-6 border p-3 overflow-auto">
          <StoryItemsManage
            _id={_id}
            storyBoards={storyBoards}
            setStoryBoards={setStoryBoards}
          />
        </div>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StoryFormPage;
