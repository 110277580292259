import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Notice = () => {
  return (
    <div className="text-start">
      <h3>
        <FontAwesomeIcon icon={faCircleCheck} />
        &nbsp; 회비 및 지출 규정
      </h3>
      <ol>
        <li
          style={{ whiteSpace: "break-Spaces", lineHeight: "190%" }}
          dangerouslySetInnerHTML={{
            __html: `<strong>회비</strong> : 가구당 7만/월(84만/년), 추가회비는 필요한 상황이 되면 상의후에 결정합니다.`,
          }}
        />
        <li
          style={{ whiteSpace: "break-Spaces", lineHeight: "190%" }}
          dangerouslySetInnerHTML={{
            __html: `<strong>사용용도</strong> : 부모님용돈 60만원(명절, 어버이날, 생신), 가족모임시 물건 구매비 지원, 기타 필요시`,
          }}
        />
        <li
          style={{ whiteSpace: "break-Spaces", lineHeight: "190%" }}
          dangerouslySetInnerHTML={{
            __html: `<strong>물건 구매시 회비사용 구분</strong> : 기존과 큰 차이없는 시장보기는 필요한 만큼 사서 단톡방에 영수증 올리거나 담당자에게 주면 되고, 금액에 큰 차이가 있으면 미리 단톡방에서 상의하고 최종적으로 담당자가 승인하면 진행해주세요. 독단적인 진행시 기부천사로 인정됩니다.`,
          }}
        />
        <li
          style={{ whiteSpace: "break-Spaces", lineHeight: "190%" }}
          dangerouslySetInnerHTML={{
            __html: `<strong>당일청구</strong> : 가능하면 바로바로 청구해주세요. 기한은 가족모임 마지막날 자정까지 입니다. 기한까지 청구하지 않으면 자발적으로 보태는걸로 이해 하겠습니다.`,
          }}
        />
      </ol>
    </div>
  );
};

export default Notice;
