import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  HandleAutoHeight,
  IsValidValue,
  decodeHTMLEntities,
} from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";
import BoardLinkForm from "./BoardLinkForm";
import BoardSentenceForm from "./BoardSentenceForm";

const BoardForm = ({ editing, moveTo, selectSentence }) => {
  const axiosHook = useAxiosPrivate();
  const navigate = useNavigate();
  const { _id } = useParams();
  // ? 뒤에 붙은 쿼리 스트링을 가져옴
  const [searchParams] = useSearchParams();
  const group = searchParams.get("group"),
    searchText = searchParams.get("searchText"),
    page = searchParams.get("page"),
    category = searchParams.get("category");
  const [orgSearchParamsText, setOrgSearchParamsText] = useState("");
  const [groups, setGroups] = useState([]);
  const [groupError, setGroupError] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryError, setCategoryError] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [originalSelectedGroup, setOriginalSelectedGroup] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [originalSelectedCategory, setOriginalSelectedCategory] = useState("");
  const [title, setTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [content, setContent] = useState("");
  const [originalContent, setOriginalContent] = useState("");
  const [isBlogPublish, setIsPublish] = useState(false);
  const [isStoryPublish, setIsStoryPublish] = useState(false);
  const [originalIsPublish, setOriginalIsPublish] = useState(false);
  const [originalIsStoryPublish, setOriginalIsStoryPublish] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { addToast } = useToast();
  const [boardLinks, setBoardLinks] = useState([]);
  const [boardSentences, setboardSentences] = useState([]);
  const [boardNo, setBoardNo] = useState(0);
  const [isLineThroughTitle, setIsLineThroughTitle] = useState(null);
  const [originalIsLineThroughTitle, setOriginalIsLineThroughTitle] =
    useState(null);
  const [isStrongTitle, setIsStrongTitle] = useState(null);
  const [originalIsStrongTitle, setOriginalIsStrongTitle] = useState(null);
  const [titleColor, setTitleColor] = useState("");
  const [originalTitleColor, setOriginalTitleColor] = useState("#0d6efd");
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    setOrgSearchParamsText(`searchText=${searchText || ""}&page=${page || ""}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // group, category가 있으면 그룹, 카테고리를 선택한 상태로 시작
  useEffect(() => {
    if (group) {
      setSelectedGroup(group);
    }
    if (category) {
      setSelectedCategory(category);
    }
  }, [group, category]);

  useEffect(() => {
    // 그룹 목록 Data 가져오기
    const getGroup = () => {
      const uri = `${apiServer}/manage/dBoard/childCodes`;
      axiosHook
        .get(uri)
        .then((res) => {
          setGroups(res.data || []);
        })
        .catch((e) => {
          console.error(e);
          setError("something went wrong in db");
          addToast({
            type: "danger",
            text: "something went wrong in db",
          });
        });
    };
    getGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiServer]);

  useEffect(() => {
    if (!selectedGroup) return;
    // 카테고리 목록 Data 가져오기
    const getCategory = () => {
      axiosHook
        .get(`${apiServer}/manage/${selectedGroup}/childCodes`)
        .then((res) => {
          setCategories(res.data || []);
        })
        .catch((e) => {
          console.error(e);
          setError("something went wrong in db");
          addToast({
            type: "danger",
            text: "something went wrong in db",
          });
        });
    };
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiServer, selectedGroup]);

  useEffect(() => {
    if (!isLoggedIn) {
      setError("Need to login");
    }
    if (editing) {
      axiosHook
        .get(`${apiServer}/board/${_id}`)
        .then((res) => {
          const board = res.data.board;
          setBoardLinks(res.data.boardLinks);
          setboardSentences(res.data.boardSentences);
          setSelectedGroup(board.gCode);
          setOriginalSelectedGroup(board.gCode);
          setSelectedCategory(board.cCode);
          setOriginalSelectedCategory(board.cCode);
          setTitle(board.title);
          setOriginalTitle(board.title);
          setContent(board.content);
          setOriginalContent(board.content);
          setIsPublish(board.isBlogPublish);
          setIsStoryPublish(board.isStoryPublish);
          setOriginalIsPublish(board.isBlogPublish);
          setOriginalIsStoryPublish(board.isStoryPublish);
          setIsLineThroughTitle(
            board.isLineThroughTitle === true ||
              board.isLineThroughTitle === "Y"
              ? true
              : false
          );
          setOriginalIsLineThroughTitle(
            board.isLineThroughTitle === true ||
              board.isLineThroughTitle === "Y"
              ? true
              : false
          );
          setIsStrongTitle(
            board.isStrongTitle || board.isStrongTitle === "Y" ? true : false
          );
          setOriginalIsStrongTitle(
            board.isStrongTitle || board.isStrongTitle === "Y" ? true : false
          );
          setTitleColor(board.titleColor);
          setOriginalTitleColor(board.titleColor);
          setBoardNo(board.boardNo);
          setIsLoading(false);
        })
        .catch(() => {
          setError("something went wrong in db");
          addToast({
            type: "danger",
            text: "something went wrong in db",
          });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    // addToast dependency 추가하면 초기화 됨
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, _id, apiServer]);

  const isEdited = () => {
    return (
      selectedGroup !== originalSelectedGroup ||
      selectedCategory !== originalSelectedCategory ||
      title !== originalTitle ||
      content !== originalContent ||
      isBlogPublish !== originalIsPublish ||
      isStoryPublish !== originalIsStoryPublish ||
      isLineThroughTitle !== originalIsLineThroughTitle ||
      isStrongTitle !== originalIsStrongTitle ||
      titleColor !== originalTitleColor
    );
  };

  const gotoView = (newId) => {
    const uri = `/manage/board/${selectedGroup}/${selectedCategory}/?boardId=${
      _id || newId
    }&${orgSearchParamsText}`;
    navigate(uri);
  };

  const validateForm = () => {
    let validated = true;
    if (!IsValidValue(selectedGroup)) {
      setGroupError("그룹을 선택하세요.");
      validated = false;
    }
    if (!IsValidValue(selectedCategory)) {
      setCategoryError("카테고리를 선택하세요.");
      validated = false;
    }
    if (!IsValidValue(title)) {
      setTitleError("제목을 입력하세요.");
      validated = false;
    }
    return validated;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (editing) {
        axiosHook
          .put(`${apiServer}/board/edit`, {
            _id,
            gCode: selectedGroup,
            cCode: selectedCategory,
            title,
            content,
            isBlogPublish,
            isStoryPublish,
            isLineThroughTitle,
            isStrongTitle,
            titleColor,
          })
          .then((res) => {
            if (res.data.ok) {
              addToast({
                type: "success",
                text: res.data.message || "Successfully updated!",
              });
              gotoView();
            } else {
              addToast({
                type: "danger",
                text: res.data.message || "We could not update board",
              });
            }
          })
          .catch((e) => {
            console.error(e);
            addToast({
              type: "danger",
              text: e.message || "We could not update board",
            });
          });
      } else {
        axiosHook
          .post(`${apiServer}/board/add`, {
            gCode: selectedGroup,
            cCode: selectedCategory,
            title,
            content,
            isBlogPublish,
            isStoryPublish,
            isLineThroughTitle,
            isStrongTitle,
            titleColor,
          })
          .then((res) => {
            if (res.data.ok) {
              addToast({
                type: "success",
                text: res.data.message || "Successfully created!",
              });
              gotoView(res.data.result);
            } else {
              addToast({
                type: "danger",
                text: res.data.message || "We could not create board",
              });
            }
          })
          .catch((e) => {
            addToast({
              type: "danger",
              text: e.message || "We could not create board",
            });
          });
      }
    }
  };

  if (isLoading) {
    return <LoadingPopSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div>
      <h2 className="h2">{editing ? "게시물 수정" : "새 게시물 등록"}</h2>
      <form>
        <div className="row">
          <ul className="m-3">
            <li className={`${groupError ? "text-danger" : "d-none"}`}>
              {groupError}
            </li>
            <li className={`${categoryError ? "text-danger" : "d-none"}`}>
              {categoryError}
            </li>
            <li className={`${titleError ? "text-danger" : "d-none"}`}>
              {titleError}
            </li>
          </ul>
          <div className="col-md-6">
            <select
              className={`form-select mb-3 ${groupError && "border-danger"}`}
              value={selectedGroup || ""}
              onChange={(event) => {
                setSelectedGroup(event.target.value);
                if (event.target.value) {
                  setGroupError("");
                }
              }}
            >
              <option value="">그룹을 선택하세요.</option>
              {groups?.map((group) => (
                <option key={group._id} value={group.code}>
                  {group.codeName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <select
              className={`form-select mb-3 ${categoryError && "border-danger"}`}
              value={selectedCategory || ""}
              onChange={(event) => {
                setSelectedCategory(event.target.value);
                if (event.target.value) {
                  setCategoryError("");
                }
              }}
            >
              <option value="">카테고리를 선택하세요.</option>
              {categories?.map((category) => (
                <option key={category._id} value={category.code}>
                  {category.codeName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3">
          {/**
           * font size options: h1, h2, h3, h4, h5, h6
           */}
          <input
            className={`form-control ${titleError ? "border-danger" : ""} ${
              isLineThroughTitle === true ? "text-decoration-line-through" : ""
            } ${isStrongTitle === true ? "fw-bold" : ""} ${
              titleColor ? "text-" + titleColor : ""
            }`}
            style={{ color: titleColor, fontSize: "1.325rem" }}
            value={title || ""}
            placeholder="제목을 입력하세요.(필수)"
            onChange={(event) => {
              setTitle(event.target.value);
              if (event.target.value) {
                setTitleError(false);
              }
            }}
          />
        </div>
        <div className="mb-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isLineThroughTitle"
              checked={isLineThroughTitle}
              onChange={(e) => setIsLineThroughTitle(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="isLineThroughTitle">
              제목에 취소선
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isStrongTitle"
              checked={isStrongTitle}
              onChange={(e) => setIsStrongTitle(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="isStrongTitle">
              제목에 강조
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="color"
              id="titleColor"
              value={titleColor}
              onChange={(e) => setTitleColor(e.target.value)}
            />
            <label className="form-check-label" htmlFor="titleColor">
              제목 색상
            </label>
          </div>
        </div>

        <div className="mb-3">
          <textarea
            className={`form-control`}
            rows="2"
            value={decodeHTMLEntities(content) || ""}
            placeholder="간단요약을 입력하세요.(선택)"
            onChange={(event) => {
              setContent(event.target.value);
            }}
            onFocus={HandleAutoHeight}
            onKeyUp={HandleAutoHeight}
            onKeyDown={HandleAutoHeight}
            onCut={HandleAutoHeight}
            onPaste={HandleAutoHeight}
          />
        </div>
        <div className="mb-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isBlogPublish"
              checked={isBlogPublish}
              onChange={(e) => setIsPublish(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="isBlogPublish">
              블로그에 공개
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="isStoryPublish"
              checked={isStoryPublish}
              onChange={(e) => setIsStoryPublish(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="isStoryPublish">
              스토리에 공개
            </label>
          </div>
        </div>
        <div className="btn-group col-12" role="group">
          {editing ? (
            <>
              <button
                className={`btn btn-primary col-6`}
                onClick={onSubmit}
                disabled={editing && !isEdited()}
              >
                저장
              </button>
              <button className={`btn btn-danger col-6`} onClick={gotoView}>
                본문으로
              </button>
            </>
          ) : (
            <button
              className={`btn btn-primary col-12}`}
              onClick={onSubmit}
              disabled={editing && !isEdited()}
            >
              저장
            </button>
          )}
        </div>
      </form>
      <div className={`dummy-sentence-root mt-3 ${editing ? "" : "d-none"}`}>
        {boardSentences?.map((bFile, idx) => (
          <BoardSentenceForm
            key={idx}
            bFile={bFile}
            boardNo={boardNo}
            boardSentences={boardSentences}
            setBoardSentences={setboardSentences}
            moveTo={moveTo}
            selectSentence={selectSentence}
          />
        ))}
        <div className="mt-3">
          <BoardSentenceForm
            boardNo={boardNo}
            boardSentences={boardSentences}
            setBoardSentences={setboardSentences}
          />
        </div>
      </div>
      <div className={`mt-4 ${editing ? "" : "d-none"}`}>
        <h3>참고 링크</h3>
        {boardLinks?.map((bLink) => (
          <BoardLinkForm
            key={bLink._id}
            bLink={bLink}
            boardNo={boardNo}
            boardLinks={boardLinks}
            setBoardLinks={setBoardLinks}
            linkId={bLink._id}
          />
        ))}
        <div className="mt-4">
          <BoardLinkForm
            boardNo={boardNo}
            boardLinks={boardLinks}
            setBoardLinks={setBoardLinks}
          />
        </div>
      </div>
    </div>
  );
};

BoardForm.propTypes = {
  editing: propTypes.bool,
};

BoardForm.defaultProps = {
  editing: false,
};

export default BoardForm;
