import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MsgBox from "../MsgBox";

const BoardSentence = ({
  _id,
  fileFolder,
  fileName,
  fileExplain,
  fileStorageType,
  fileTitle,
  folderOrgName,
  isManagePage,
}) => {
  const [error, setError] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [fileUri, setFileUri] = useState("");
  const fileRootUri = process.env.REACT_APP_FILE_ROOT_URI;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (isManagePage && !isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch, isManagePage]);

  useEffect(() => {
    if (!fileName) return;
    fileStorageType === "B"
      ? setFileUri(`${fileRootUri}${fileFolder}/${fileName}`)
      : setFileUri(`${fileRootUri}/upload/board2016/${fileFolder}/${fileName}`);

    if (folderOrgName) {
      const fileExt = folderOrgName.split(".").pop();
      if (
        ["jpg", "jpeg", "png", "gif", "ico"].includes(fileExt.toLowerCase())
      ) {
        setIsImage(true);
      }
    }
  }, [fileFolder, fileName, fileStorageType, fileRootUri, folderOrgName]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: fileTitle
            ? `<h3>${fileTitle
                ?.replaceAll("&lt;h3&gt;", "")
                .replaceAll("&lt;/h3&gt;", "")}</h3>`
            : "",
        }}
      ></div>
      {fileName ? (
        <>
          {isImage ? (
            <div className="text-center">
              <img
                src={fileUri}
                alt={folderOrgName || _id}
                className="border border-1 rounded border-secondary"
                style={{ maxWidth: "98%" }}
              />
              <p
                className={`w-100 text-center fst-italic text-muted small ${
                  folderOrgName || fileName ? "" : "d-none"
                }`}
              >
                &lt;{folderOrgName || fileName}&gt;
              </p>
            </div>
          ) : (
            <div className="fw-bold p-2 my-3" style={{ backgroundColor: "#faf9dc" }}>
              <a rel="noopener noreferrer" href={fileUri} target="_blank">
                {folderOrgName}
              </a>
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <div>
        <pre
          dangerouslySetInnerHTML={{
            __html: fileExplain
              ?.replaceAll("&lt;", "<")
              .replaceAll("&gt;", ">"),
          }}
        ></pre>
      </div>
    </>
  );
};

export default BoardSentence;
